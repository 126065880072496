/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import {
  PageInfoFieldsFragmentDoc,
  PostMeiliPageInfoFieldsFragmentDoc
} from '../../graphql/fragments/page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type WorshipedPersonFieldsFragment = {
  __typename?: 'WorshipedPerson';
  id: string;
  name: string;
  wikiAvatar?: string | null;
  countryOfCitizenship?: string | null;
  achievement?: string | null;
  bio?: string | null;
  alias?: string | null;
  religion?: string | null;
  placeOfBirth?: string | null;
  placeOfDeath?: string | null;
  placeOfBurial?: string | null;
  quote?: string | null;
  wikiDataId?: string | null;
  totalWorshipAmount?: number | null;
  dateOfBirth?: any | null;
  dateOfDeath?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  avatar?: {
    __typename?: 'UploadDetail';
    id: string;
    upload: {
      __typename?: 'Upload';
      id: string;
      sha: string;
      bucket?: string | null;
      width?: number | null;
      height?: number | null;
    };
  } | null;
  country?: { __typename?: 'Country'; id: string; name: string } | null;
  state?: { __typename?: 'State'; id: string; name: string } | null;
  city?: { __typename?: 'City'; id: string; name: string } | null;
};

export type WorshipFieldsFragment = {
  __typename?: 'Worship';
  id: string;
  worshipedAmount: number;
  location?: string | null;
  latitude?: any | null;
  longitude?: any | null;
  createdAt: any;
  updatedAt: any;
  account: { __typename?: 'Account'; id: number; name: string; address: string };
  worshipedPerson?: {
    __typename?: 'WorshipedPerson';
    id: string;
    name: string;
    totalWorshipAmount?: number | null;
  } | null;
  temple?: { __typename?: 'Temple'; id: string; name: string; totalWorshipAmount?: number | null } | null;
};

export type WorshipedPersonQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type WorshipedPersonQuery = {
  __typename?: 'Query';
  worshipedPerson: {
    __typename?: 'WorshipedPerson';
    id: string;
    name: string;
    wikiAvatar?: string | null;
    countryOfCitizenship?: string | null;
    achievement?: string | null;
    bio?: string | null;
    alias?: string | null;
    religion?: string | null;
    placeOfBirth?: string | null;
    placeOfDeath?: string | null;
    placeOfBurial?: string | null;
    quote?: string | null;
    wikiDataId?: string | null;
    totalWorshipAmount?: number | null;
    dateOfBirth?: any | null;
    dateOfDeath?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    avatar?: {
      __typename?: 'UploadDetail';
      id: string;
      upload: {
        __typename?: 'Upload';
        id: string;
        sha: string;
        bucket?: string | null;
        width?: number | null;
        height?: number | null;
      };
    } | null;
    country?: { __typename?: 'Country'; id: string; name: string } | null;
    state?: { __typename?: 'State'; id: string; name: string } | null;
    city?: { __typename?: 'City'; id: string; name: string } | null;
  };
};

export type WorshipQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type WorshipQuery = {
  __typename?: 'Query';
  worship: {
    __typename?: 'Worship';
    id: string;
    worshipedAmount: number;
    location?: string | null;
    latitude?: any | null;
    longitude?: any | null;
    createdAt: any;
    updatedAt: any;
    account: { __typename?: 'Account'; id: number; name: string; address: string };
    worshipedPerson?: {
      __typename?: 'WorshipedPerson';
      id: string;
      name: string;
      totalWorshipAmount?: number | null;
    } | null;
    temple?: { __typename?: 'Temple'; id: string; name: string; totalWorshipAmount?: number | null } | null;
  };
};

export type WorshipedPeopleQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.WorshipedPersonOrder>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type WorshipedPeopleQuery = {
  __typename?: 'Query';
  allWorshipedPerson: {
    __typename?: 'WorshipedPersonConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'WorshipedPersonEdge';
      cursor: string;
      node: {
        __typename?: 'WorshipedPerson';
        id: string;
        name: string;
        wikiAvatar?: string | null;
        countryOfCitizenship?: string | null;
        achievement?: string | null;
        bio?: string | null;
        alias?: string | null;
        religion?: string | null;
        placeOfBirth?: string | null;
        placeOfDeath?: string | null;
        placeOfBurial?: string | null;
        quote?: string | null;
        wikiDataId?: string | null;
        totalWorshipAmount?: number | null;
        dateOfBirth?: any | null;
        dateOfDeath?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        avatar?: {
          __typename?: 'UploadDetail';
          id: string;
          upload: {
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
          };
        } | null;
        country?: { __typename?: 'Country'; id: string; name: string } | null;
        state?: { __typename?: 'State'; id: string; name: string } | null;
        city?: { __typename?: 'City'; id: string; name: string } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type WorshipedPeopleByUserIdQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.WorshipedPersonOrder>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type WorshipedPeopleByUserIdQuery = {
  __typename?: 'Query';
  allWorshipedPersonByUserId: {
    __typename?: 'WorshipedPersonConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'WorshipedPersonEdge';
      cursor: string;
      node: {
        __typename?: 'WorshipedPerson';
        id: string;
        name: string;
        wikiAvatar?: string | null;
        countryOfCitizenship?: string | null;
        achievement?: string | null;
        bio?: string | null;
        alias?: string | null;
        religion?: string | null;
        placeOfBirth?: string | null;
        placeOfDeath?: string | null;
        placeOfBurial?: string | null;
        quote?: string | null;
        wikiDataId?: string | null;
        totalWorshipAmount?: number | null;
        dateOfBirth?: any | null;
        dateOfDeath?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        avatar?: {
          __typename?: 'UploadDetail';
          id: string;
          upload: {
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
          };
        } | null;
        country?: { __typename?: 'Country'; id: string; name: string } | null;
        state?: { __typename?: 'State'; id: string; name: string } | null;
        city?: { __typename?: 'City'; id: string; name: string } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type WorshipedPersonBySearchQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  query?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type WorshipedPersonBySearchQuery = {
  __typename?: 'Query';
  allWorshipedPersonBySearch: {
    __typename?: 'WorshipedPersonConnection';
    edges?: Array<{
      __typename?: 'WorshipedPersonEdge';
      cursor: string;
      node: {
        __typename?: 'WorshipedPerson';
        id: string;
        name: string;
        wikiAvatar?: string | null;
        countryOfCitizenship?: string | null;
        achievement?: string | null;
        bio?: string | null;
        alias?: string | null;
        religion?: string | null;
        placeOfBirth?: string | null;
        placeOfDeath?: string | null;
        placeOfBurial?: string | null;
        quote?: string | null;
        wikiDataId?: string | null;
        totalWorshipAmount?: number | null;
        dateOfBirth?: any | null;
        dateOfDeath?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        avatar?: {
          __typename?: 'UploadDetail';
          id: string;
          upload: {
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
          };
        } | null;
        country?: { __typename?: 'Country'; id: string; name: string } | null;
        state?: { __typename?: 'State'; id: string; name: string } | null;
        city?: { __typename?: 'City'; id: string; name: string } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type WorshipedPeopleSpecialDateQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.WorshipedPersonOrder>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type WorshipedPeopleSpecialDateQuery = {
  __typename?: 'Query';
  allWorshipedPersonSpecialDate: {
    __typename?: 'WorshipedPersonConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'WorshipedPersonEdge';
      cursor: string;
      node: {
        __typename?: 'WorshipedPerson';
        id: string;
        name: string;
        wikiAvatar?: string | null;
        countryOfCitizenship?: string | null;
        achievement?: string | null;
        bio?: string | null;
        alias?: string | null;
        religion?: string | null;
        placeOfBirth?: string | null;
        placeOfDeath?: string | null;
        placeOfBurial?: string | null;
        quote?: string | null;
        wikiDataId?: string | null;
        totalWorshipAmount?: number | null;
        dateOfBirth?: any | null;
        dateOfDeath?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        avatar?: {
          __typename?: 'UploadDetail';
          id: string;
          upload: {
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
          };
        } | null;
        country?: { __typename?: 'Country'; id: string; name: string } | null;
        state?: { __typename?: 'State'; id: string; name: string } | null;
        city?: { __typename?: 'City'; id: string; name: string } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type AllWorshipedByPersonIdQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.WorshipOrder>;
  id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type AllWorshipedByPersonIdQuery = {
  __typename?: 'Query';
  allWorshipedByPersonId: {
    __typename?: 'WorshipConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'WorshipEdge';
      cursor: string;
      node: {
        __typename?: 'Worship';
        id: string;
        worshipedAmount: number;
        location?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        createdAt: any;
        updatedAt: any;
        account: { __typename?: 'Account'; id: number; name: string; address: string };
        worshipedPerson?: {
          __typename?: 'WorshipedPerson';
          id: string;
          name: string;
          totalWorshipAmount?: number | null;
        } | null;
        temple?: { __typename?: 'Temple'; id: string; name: string; totalWorshipAmount?: number | null } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type AllWorshipedByTempleIdQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.WorshipOrder>;
  id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type AllWorshipedByTempleIdQuery = {
  __typename?: 'Query';
  allWorshipedByTempleId: {
    __typename?: 'WorshipConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'WorshipEdge';
      cursor: string;
      node: {
        __typename?: 'Worship';
        id: string;
        worshipedAmount: number;
        location?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        createdAt: any;
        updatedAt: any;
        account: { __typename?: 'Account'; id: number; name: string; address: string };
        worshipedPerson?: {
          __typename?: 'WorshipedPerson';
          id: string;
          name: string;
          totalWorshipAmount?: number | null;
        } | null;
        temple?: { __typename?: 'Temple'; id: string; name: string; totalWorshipAmount?: number | null } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type AllWorshipQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.WorshipOrder>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type AllWorshipQuery = {
  __typename?: 'Query';
  allWorship: {
    __typename?: 'WorshipConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'WorshipEdge';
      cursor: string;
      node: {
        __typename?: 'Worship';
        id: string;
        worshipedAmount: number;
        location?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        createdAt: any;
        updatedAt: any;
        account: { __typename?: 'Account'; id: number; name: string; address: string };
        worshipedPerson?: {
          __typename?: 'WorshipedPerson';
          id: string;
          name: string;
          totalWorshipAmount?: number | null;
        } | null;
        temple?: { __typename?: 'Temple'; id: string; name: string; totalWorshipAmount?: number | null } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type CreateWorshipedPersonMutationVariables = Types.Exact<{
  input: Types.CreateWorshipedPersonInput;
}>;

export type CreateWorshipedPersonMutation = {
  __typename?: 'Mutation';
  createWorshipedPerson: {
    __typename?: 'WorshipedPerson';
    id: string;
    name: string;
    wikiAvatar?: string | null;
    countryOfCitizenship?: string | null;
    achievement?: string | null;
    bio?: string | null;
    alias?: string | null;
    religion?: string | null;
    placeOfBirth?: string | null;
    placeOfDeath?: string | null;
    placeOfBurial?: string | null;
    quote?: string | null;
    wikiDataId?: string | null;
    totalWorshipAmount?: number | null;
    dateOfBirth?: any | null;
    dateOfDeath?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    avatar?: {
      __typename?: 'UploadDetail';
      id: string;
      upload: {
        __typename?: 'Upload';
        id: string;
        sha: string;
        bucket?: string | null;
        width?: number | null;
        height?: number | null;
      };
    } | null;
    country?: { __typename?: 'Country'; id: string; name: string } | null;
    state?: { __typename?: 'State'; id: string; name: string } | null;
    city?: { __typename?: 'City'; id: string; name: string } | null;
  };
};

export type CreateWorshipMutationVariables = Types.Exact<{
  input: Types.CreateWorshipInput;
}>;

export type CreateWorshipMutation = {
  __typename?: 'Mutation';
  createWorship: {
    __typename?: 'Worship';
    id: string;
    worshipedAmount: number;
    location?: string | null;
    latitude?: any | null;
    longitude?: any | null;
    createdAt: any;
    updatedAt: any;
    account: { __typename?: 'Account'; id: number; name: string; address: string };
    worshipedPerson?: {
      __typename?: 'WorshipedPerson';
      id: string;
      name: string;
      totalWorshipAmount?: number | null;
    } | null;
    temple?: { __typename?: 'Temple'; id: string; name: string; totalWorshipAmount?: number | null } | null;
  };
};

export type CreateWorshipTempleMutationVariables = Types.Exact<{
  input: Types.CreateWorshipInput;
}>;

export type CreateWorshipTempleMutation = {
  __typename?: 'Mutation';
  createWorshipTemple: {
    __typename?: 'Worship';
    id: string;
    worshipedAmount: number;
    location?: string | null;
    latitude?: any | null;
    longitude?: any | null;
    createdAt: any;
    updatedAt: any;
    account: { __typename?: 'Account'; id: number; name: string; address: string };
    worshipedPerson?: {
      __typename?: 'WorshipedPerson';
      id: string;
      name: string;
      totalWorshipAmount?: number | null;
    } | null;
    temple?: { __typename?: 'Temple'; id: string; name: string; totalWorshipAmount?: number | null } | null;
  };
};

export const WorshipedPersonFieldsFragmentDoc = `
    fragment WorshipedPersonFields on WorshipedPerson {
  id
  name
  avatar {
    id
    upload {
      id
      sha
      bucket
      width
      height
    }
  }
  wikiAvatar
  countryOfCitizenship
  achievement
  bio
  alias
  religion
  placeOfBirth
  placeOfDeath
  placeOfBurial
  quote
  wikiDataId
  totalWorshipAmount
  dateOfBirth
  dateOfDeath
  country {
    id
    name
  }
  state {
    id
    name
  }
  city {
    id
    name
  }
  createdAt
  updatedAt
}
    `;
export const WorshipFieldsFragmentDoc = `
    fragment WorshipFields on Worship {
  id
  account {
    id
    name
    address
  }
  worshipedPerson {
    id
    name
    totalWorshipAmount
  }
  temple {
    id
    name
    totalWorshipAmount
  }
  worshipedAmount
  location
  latitude
  longitude
  createdAt
  updatedAt
}
    `;
export const WorshipedPersonDocument = `
    query WorshipedPerson($id: String!) {
  worshipedPerson(id: $id) {
    ...WorshipedPersonFields
  }
}
    ${WorshipedPersonFieldsFragmentDoc}`;
export const WorshipDocument = `
    query Worship($id: String!) {
  worship(id: $id) {
    ...WorshipFields
  }
}
    ${WorshipFieldsFragmentDoc}`;
export const WorshipedPeopleDocument = `
    query WorshipedPeople($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: WorshipedPersonOrder, $skip: Int) {
  allWorshipedPerson(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    skip: $skip
  ) {
    totalCount
    edges {
      cursor
      node {
        ...WorshipedPersonFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${WorshipedPersonFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const WorshipedPeopleByUserIdDocument = `
    query WorshipedPeopleByUserId($after: String, $before: String, $first: Int = 5, $last: Int, $orderBy: WorshipedPersonOrder, $skip: Int) {
  allWorshipedPersonByUserId(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    skip: $skip
  ) {
    totalCount
    edges {
      cursor
      node {
        ...WorshipedPersonFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${WorshipedPersonFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const WorshipedPersonBySearchDocument = `
    query WorshipedPersonBySearch($after: String, $before: String, $first: Int, $last: Int, $query: String) {
  allWorshipedPersonBySearch(
    after: $after
    before: $before
    first: $first
    last: $last
    query: $query
  ) {
    edges {
      cursor
      node {
        ...WorshipedPersonFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${WorshipedPersonFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const WorshipedPeopleSpecialDateDocument = `
    query WorshipedPeopleSpecialDate($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: WorshipedPersonOrder, $skip: Int) {
  allWorshipedPersonSpecialDate(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    skip: $skip
  ) {
    totalCount
    edges {
      cursor
      node {
        ...WorshipedPersonFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${WorshipedPersonFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const AllWorshipedByPersonIdDocument = `
    query allWorshipedByPersonId($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: WorshipOrder, $id: String, $skip: Int) {
  allWorshipedByPersonId(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    id: $id
    skip: $skip
  ) {
    totalCount
    edges {
      cursor
      node {
        ...WorshipFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${WorshipFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const AllWorshipedByTempleIdDocument = `
    query allWorshipedByTempleId($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: WorshipOrder, $id: String, $skip: Int) {
  allWorshipedByTempleId(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    id: $id
    skip: $skip
  ) {
    totalCount
    edges {
      cursor
      node {
        ...WorshipFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${WorshipFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const AllWorshipDocument = `
    query allWorship($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: WorshipOrder, $skip: Int) {
  allWorship(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    skip: $skip
  ) {
    totalCount
    edges {
      cursor
      node {
        ...WorshipFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${WorshipFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const CreateWorshipedPersonDocument = `
    mutation createWorshipedPerson($input: CreateWorshipedPersonInput!) {
  createWorshipedPerson(data: $input) {
    ...WorshipedPersonFields
  }
}
    ${WorshipedPersonFieldsFragmentDoc}`;
export const CreateWorshipDocument = `
    mutation createWorship($input: CreateWorshipInput!) {
  createWorship(data: $input) {
    ...WorshipFields
  }
}
    ${WorshipFieldsFragmentDoc}`;
export const CreateWorshipTempleDocument = `
    mutation CreateWorshipTemple($input: CreateWorshipInput!) {
  createWorshipTemple(data: $input) {
    ...WorshipFields
  }
}
    ${WorshipFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    WorshipedPerson: build.query<WorshipedPersonQuery, WorshipedPersonQueryVariables>({
      query: variables => ({ document: WorshipedPersonDocument, variables })
    }),
    Worship: build.query<WorshipQuery, WorshipQueryVariables>({
      query: variables => ({ document: WorshipDocument, variables })
    }),
    WorshipedPeople: build.query<WorshipedPeopleQuery, WorshipedPeopleQueryVariables | void>({
      query: variables => ({ document: WorshipedPeopleDocument, variables })
    }),
    WorshipedPeopleByUserId: build.query<WorshipedPeopleByUserIdQuery, WorshipedPeopleByUserIdQueryVariables | void>({
      query: variables => ({ document: WorshipedPeopleByUserIdDocument, variables })
    }),
    WorshipedPersonBySearch: build.query<WorshipedPersonBySearchQuery, WorshipedPersonBySearchQueryVariables | void>({
      query: variables => ({ document: WorshipedPersonBySearchDocument, variables })
    }),
    WorshipedPeopleSpecialDate: build.query<
      WorshipedPeopleSpecialDateQuery,
      WorshipedPeopleSpecialDateQueryVariables | void
    >({
      query: variables => ({ document: WorshipedPeopleSpecialDateDocument, variables })
    }),
    allWorshipedByPersonId: build.query<AllWorshipedByPersonIdQuery, AllWorshipedByPersonIdQueryVariables | void>({
      query: variables => ({ document: AllWorshipedByPersonIdDocument, variables })
    }),
    allWorshipedByTempleId: build.query<AllWorshipedByTempleIdQuery, AllWorshipedByTempleIdQueryVariables | void>({
      query: variables => ({ document: AllWorshipedByTempleIdDocument, variables })
    }),
    allWorship: build.query<AllWorshipQuery, AllWorshipQueryVariables | void>({
      query: variables => ({ document: AllWorshipDocument, variables })
    }),
    createWorshipedPerson: build.mutation<CreateWorshipedPersonMutation, CreateWorshipedPersonMutationVariables>({
      query: variables => ({ document: CreateWorshipedPersonDocument, variables })
    }),
    createWorship: build.mutation<CreateWorshipMutation, CreateWorshipMutationVariables>({
      query: variables => ({ document: CreateWorshipDocument, variables })
    }),
    CreateWorshipTemple: build.mutation<CreateWorshipTempleMutation, CreateWorshipTempleMutationVariables>({
      query: variables => ({ document: CreateWorshipTempleDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
