/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { PostFieldsFragmentDoc, OfferFieldsFragmentDoc } from '../post/posts.generated';
import { BasicPageInfoFieldsFragmentDoc } from '../../graphql/fragments/basic-page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type AllOfferQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type AllOfferQuery = {
  __typename?: 'Query';
  allOffer: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data: {
          __typename: 'Post';
          id: string;
          content: string;
          accountId: number;
          pageId?: string | null;
          tokenId?: string | null;
          repostCount: number;
          totalComments: number;
          commentableId?: string | null;
          createdAt: any;
          updatedAt: any;
          followPostOwner?: boolean | null;
          followedPage?: boolean | null;
          followedToken?: boolean | null;
          bookmarkableId?: string | null;
          isBookmarked?: boolean | null;
          originalLanguage?: string | null;
          danaViewScore?: number | null;
          burnedByOthers?: boolean | null;
          account: {
            __typename?: 'Account';
            address: string;
            hash160?: string | null;
            id: number;
            name: string;
            avatar?: string | null;
            createCommentFee?: string | null;
          };
          page?: {
            __typename?: 'Page';
            avatar?: string | null;
            name: string;
            id: string;
            createPostFee: string;
            createCommentFee: string;
            pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
          } | null;
          token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
          reposts?: Array<{
            __typename?: 'Repost';
            accountId?: number | null;
            account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
          }> | null;
          dana?: {
            __typename?: 'PostDana';
            danaBurnUp: number;
            danaBurnDown: number;
            danaBurnScore: number;
            danaReceivedUp: number;
            danaReceivedDown: number;
            danaReceivedScore: number;
            version: number;
          } | null;
          boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
          translations?: Array<{
            __typename?: 'PostTranslation';
            id: string;
            translateContent?: string | null;
            translateLanguage?: string | null;
          }> | null;
          imageUploadable?: {
            __typename?: 'ImageUploadable';
            id: string;
            uploads: Array<{
              __typename?: 'Upload';
              id: string;
              sha: string;
              bucket?: string | null;
              width?: number | null;
              height?: number | null;
              cfImageId?: string | null;
              cfImageFilename?: string | null;
            }>;
          } | null;
          poll?: {
            __typename?: 'Poll';
            postId: string;
            question: string;
            startDate: any;
            endDate: any;
            canAddOption: boolean;
            singleSelect: boolean;
            defaultOptions?: Array<string> | null;
            totalVote?: number | null;
            options: Array<{
              __typename?: 'PollOption';
              id: string;
              option: string;
              pollId: string;
              danaScoreOption?: number | null;
              pollAnswerOnAccount?: Array<{
                __typename?: 'PollAnswerOnAccount';
                pollDanaScore: number;
                accountId: number;
              }> | null;
            }>;
          } | null;
          offer?: {
            __typename?: 'Offer';
            postId: string;
            publicKey: string;
            message: string;
            price: string;
            orderLimitMin: number;
            orderLimitMax: number;
            paymentMethods: Array<{
              __typename?: 'OfferPaymentMethod';
              paymentMethod: { __typename?: 'PaymentMethod'; name: string };
            }>;
          } | null;
        };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type AllOfferByPublicKeyQueryVariables = Types.Exact<{
  publicKey: Types.Scalars['String']['input'];
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type AllOfferByPublicKeyQuery = {
  __typename?: 'Query';
  allOfferByPublicKey: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data: {
          __typename: 'Post';
          id: string;
          content: string;
          accountId: number;
          pageId?: string | null;
          tokenId?: string | null;
          repostCount: number;
          totalComments: number;
          commentableId?: string | null;
          createdAt: any;
          updatedAt: any;
          followPostOwner?: boolean | null;
          followedPage?: boolean | null;
          followedToken?: boolean | null;
          bookmarkableId?: string | null;
          isBookmarked?: boolean | null;
          originalLanguage?: string | null;
          danaViewScore?: number | null;
          burnedByOthers?: boolean | null;
          account: {
            __typename?: 'Account';
            address: string;
            hash160?: string | null;
            id: number;
            name: string;
            avatar?: string | null;
            createCommentFee?: string | null;
          };
          page?: {
            __typename?: 'Page';
            avatar?: string | null;
            name: string;
            id: string;
            createPostFee: string;
            createCommentFee: string;
            pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
          } | null;
          token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
          reposts?: Array<{
            __typename?: 'Repost';
            accountId?: number | null;
            account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
          }> | null;
          dana?: {
            __typename?: 'PostDana';
            danaBurnUp: number;
            danaBurnDown: number;
            danaBurnScore: number;
            danaReceivedUp: number;
            danaReceivedDown: number;
            danaReceivedScore: number;
            version: number;
          } | null;
          boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
          translations?: Array<{
            __typename?: 'PostTranslation';
            id: string;
            translateContent?: string | null;
            translateLanguage?: string | null;
          }> | null;
          imageUploadable?: {
            __typename?: 'ImageUploadable';
            id: string;
            uploads: Array<{
              __typename?: 'Upload';
              id: string;
              sha: string;
              bucket?: string | null;
              width?: number | null;
              height?: number | null;
              cfImageId?: string | null;
              cfImageFilename?: string | null;
            }>;
          } | null;
          poll?: {
            __typename?: 'Poll';
            postId: string;
            question: string;
            startDate: any;
            endDate: any;
            canAddOption: boolean;
            singleSelect: boolean;
            defaultOptions?: Array<string> | null;
            totalVote?: number | null;
            options: Array<{
              __typename?: 'PollOption';
              id: string;
              option: string;
              pollId: string;
              danaScoreOption?: number | null;
              pollAnswerOnAccount?: Array<{
                __typename?: 'PollAnswerOnAccount';
                pollDanaScore: number;
                accountId: number;
              }> | null;
            }>;
          } | null;
          offer?: {
            __typename?: 'Offer';
            postId: string;
            publicKey: string;
            message: string;
            price: string;
            orderLimitMin: number;
            orderLimitMax: number;
            paymentMethods: Array<{
              __typename?: 'OfferPaymentMethod';
              paymentMethod: { __typename?: 'PaymentMethod'; name: string };
            }>;
          } | null;
        };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type CreateOfferMutationVariables = Types.Exact<{
  input: Types.CreateOfferInput;
}>;

export type CreateOfferMutation = {
  __typename?: 'Mutation';
  createOffer: {
    __typename?: 'Offer';
    postId: string;
    publicKey: string;
    message: string;
    price: string;
    orderLimitMin: number;
    orderLimitMax: number;
    paymentMethods: Array<{
      __typename?: 'OfferPaymentMethod';
      paymentMethod: { __typename?: 'PaymentMethod'; name: string };
    }>;
  };
};

export const AllOfferDocument = `
    query AllOffer($first: Int = 20, $after: String) {
  allOffer(first: $first, after: $after) {
    totalCount
    edges {
      cursor
      node {
        id
        data {
          __typename
          ... on Post {
            ...PostFields
          }
        }
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${PostFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const AllOfferByPublicKeyDocument = `
    query AllOfferByPublicKey($publicKey: String!, $first: Int = 20, $after: String) {
  allOfferByPublicKey(publicKey: $publicKey, first: $first, after: $after) {
    totalCount
    edges {
      cursor
      node {
        id
        data {
          __typename
          ... on Post {
            ...PostFields
          }
        }
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${PostFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const CreateOfferDocument = `
    mutation CreateOffer($input: CreateOfferInput!) {
  createOffer(data: $input) {
    ...OfferFields
  }
}
    ${OfferFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    AllOffer: build.query<AllOfferQuery, AllOfferQueryVariables | void>({
      query: variables => ({ document: AllOfferDocument, variables })
    }),
    AllOfferByPublicKey: build.query<AllOfferByPublicKeyQuery, AllOfferByPublicKeyQueryVariables>({
      query: variables => ({ document: AllOfferByPublicKeyDocument, variables })
    }),
    CreateOffer: build.mutation<CreateOfferMutation, CreateOfferMutationVariables>({
      query: variables => ({ document: CreateOfferDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
